<template>
  <v-row>
    <template v-if="e2eeRegistered === true">
      <v-col :cols="12" :sm="7">
        End-to-end encryption (E2EE) is enabled on your account.  Please share your E2EE passcode with your report recipients.
      </v-col>
      <v-col :cols="12" :sm="5" class="pl-2 pr-0 pt-0 pb-0">
        <v-dialog v-model="showE2EEPasscodeChangeDialog" max-width="800px" :fullscreen="$store.state.isMobile" @close="cleanupPasscodeDialog">
          <template v-slot:activator="{ on }">
            <v-btn
              class="mb-2"
              color="primary"
              v-on="on"
              outlined
              block
              width="100%"
            >Change E2EE Passcode</v-btn>
          </template>
          <v-card>
            <v-card-title class="d-inline-block" style="width: 100%;">
              <span>Change End-to-End Encryption (E2EE) Recovery Passcode</span>
              <v-btn color="primary" @click="showE2EEPasscodeChangeDialog = false" :disabled="dialogLoading" icon class="float-right">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-alert type="error">
                This will <b>NOT</b> change your <b>account</b> password.
              </v-alert>
              <div>
                You can change your E2EE Recovery passcode at anytime.
                You and your report recipients will not need to enter the new passcode unless a new device/browser is used.
                Once changed, the old passcode will no longer be valid.
              </div>

              <div class="font-italic caption mb-2 mt-2">Changing the E2EE passcode does <b>NOT</b> generate a new certificate key pair.</div>

              <form id="changeE2eePasscodeForm">

                <input type="text" name="usr" :value="decryptionPasscodeUsername" style="display: none;" :readonly="true" autocomplete="username" />

                <e2-e-e-passcode-input
                    :disabled="dialogLoading"
                    v-model="originalE2eePasscode"
                    :persistent-hint="true"
                    type="password"
                    placeholder="Enter your current passcode"
                    autocomplete="current-password"
                    hint="Current Passcode.  If you've forgotten your current passcode, then you must reset your passcode.">
                </e2-e-e-passcode-input>

<!--                <v-text-field-->
<!--                  :disabled="dialogLoading"-->
<!--                  v-model="originalE2eePasscode"-->
<!--                  :persistent-hint="true"-->
<!--                  type="password"-->
<!--                  placeholder="Enter your current passcode"-->
<!--                  autocomplete="current-password"-->
<!--                  hint="Current Passcode.  If you've forgotten your current passcode, then you must reset your passcode.">-->
<!--                </v-text-field>-->

<!--                <v-text-field-->
<!--                  :disabled="dialogLoading"-->
<!--                  type="password"-->
<!--                  v-model="e2eePasscode"-->
<!--                  :persistent-hint="true"-->
<!--                  placeholder="Enter your new passcode"-->
<!--                  autocomplete="new-password"-->
<!--                  hint="New Passcode">-->

<!--                </v-text-field>-->

                <e2-e-e-passcode-input
                    :disabled="dialogLoading"
                    type="password"
                    v-model="e2eePasscode"
                    :persistent-hint="true"
                    placeholder="Enter your new passcode"
                    autocomplete="new-password"
                    hint="New Passcode" />

                <e2-e-e-passcode-input
                  :disabled="dialogLoading"
                  type="password"
                  v-model="e2eePasscodeConfirm"
                  :persistent-hint="true"
                  placeholder="Confirm your new passcode"
                  autocomplete="new-password"
                  hint="Confirm New Passcode" />
              </form>

              <passcode-requirements />

              <v-alert type="error" v-if="errorMessage" class="mt-2" dense>
                {{errorMessage}}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn :outlined="!$store.state.isMobile" color="secondary" @click="showE2EEPasscodeChangeDialog = false" :disabled="dialogLoading" :icon="$store.state.isMobile">
                <v-icon>close</v-icon>
                <span v-if="!$store.state.isMobile">Close</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="showE2EEPasscodeChangeDialog = !(showE2EEPasscodeResetDialog = true)" :disabled="dialogLoading" >forgot passcode</v-btn>
              <v-btn color="primary" :disabled="!passCodeValid || dialogLoading" @click="changePasscode" :loading="dialogLoading">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showE2EEPasscodeResetDialog" max-width="800px" :fullscreen="$store.state.isMobile" @close="cleanupPasscodeDialog">
<!--            <template v-slot:activator="{ on }">-->
<!--              <v-btn-->
<!--                color="primary"-->
<!--                v-on="on"-->
<!--                outlined-->
<!--                block-->
<!--                width="100%"-->
<!--                :disabled="$store.getters.isAccountLocked"-->
<!--              >Reset E2EE Passcode</v-btn>-->
<!--            </template>-->
          <v-card>
            <v-card-title>
              Rotate E2EE Passcode
            </v-card-title>
            <v-card-text>

              <div>
                You can rotate the E2EE passcode if you have forgotten your current passcode.
              </div>
              <div class="font-italic caption">Resetting the E2EE passcode <b>does</b> generate a new certificate key pair.</div>

              <passcode-requirements />


              <form id="resetDecryptionPasscodeForm">
                <input type="text" name="usr" :value="decryptionPasscodeUsername" style="display: none;" :readonly="true" autocomplete="username" />

                <e2-e-e-passcode-input
                  :disabled="dialogLoading"
                  type="password"
                  v-model="e2eePasscode"
                  :persistent-hint="true"
                  placeholder="Enter your new passcode"
                  autocomplete="new-password"
                  hint="New Passcode">

                </e2-e-e-passcode-input>

                <e2-e-e-passcode-input
                  :disabled="dialogLoading"
                  type="password"
                  v-model="e2eePasscodeConfirm"
                  :persistent-hint="true"
                  placeholder="Confirm your new passcode"
                  autocomplete="new-password"
                  hint="Confirm New Passcode">

                </e2-e-e-passcode-input>
              </form>

              <v-alert type="warning">
                Rotating the passcode will cause you and your report recipients to lose access to all existing screenshots, websites, & event data.  Only data encrypted after you change the passcode will be accessible.
              </v-alert>

              <v-btn color="primary" block :disabled="!passCodeValid || dialogLoading" :loading="dialogLoading" @click="rotatePasscode">
                Rotate Passcode
              </v-btn>

              <v-alert type="error" v-if="errorMessage" class="mt-2" dense>
                {{errorMessage}}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn outlined :disabled="dialogLoading" @click="showE2EEPasscodeResetDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </template>

    <template v-else-if="e2eeRegistered === false">
      <v-col :cols="12" :sm="5" :offset-sm="7" class="pt-1 pb-4 pl-2 pr-2">
        <v-dialog v-model="showE2EEDialog" max-width="800px" :fullscreen="$store.state.isMobile" @close="cleanupPasscodeDialog">
          <template v-slot:activator="{ on }">
            <v-btn
              color="warning"
              v-on="on"
              outlined
              block
              width="100%"
            >Enable E2E Encryption</v-btn>
          </template>
          <v-card>
            <v-card-title>
              End-to-End Encryption (E2EE)
            </v-card-title>
            <v-card-text>
              <template v-if="currentState === 0">
                <div class="mb-2">End-to-end encryption (E2EE) is a crucial security feature that safeguards your data. To enable E2EE, you'll need to set up an E2EE passcode, which you'll be prompted to enter from time to time. We recommend writing this passcode down in a secure place. If you want others to access your accountability report, you'll need to share the passcode with them.</div>
              </template>
              <template v-else-if="currentState === 1">
                <div class="mb-2">Your E2EE passcode functions like a Wi-Fi password. <span class="">Just as someone needs your Wi-Fi password to connect to your Wi-Fi, anyone who wants to view your report must enter your E2EE passcode.</span></div>
                <div class="mb-2">For more information, visit our <a href="https://blog.truple.io/2020/07/08/end-to-end-encryption-accountability-app.html#end-to-end-encryption-frequently-asked-questions" target="_blank">E2EE Announcement</a>.</div>
              </template>
              <template v-else-if="currentState === 2">
                <div class="headline">E2EE Recovery Passcode</div>

                We strongly recommend you:
                <ul>
                  <li>Write down your passcode on paper</li>
                  <li>Let your browser save your passcode</li>
                  <li>Share your e2ee passcode with report recipients</li>
                </ul>
              </template>
              <template v-else>
                <v-expand-transition>
                  <v-card outlined v-if="!showEnableE2EEForm">
                    <v-card-text>
                      <v-alert type="warning">
                        Make sure you write your E2EE passcode down somewhere safe!
                      </v-alert>
                      <v-alert type="warning">
                        You <b>must</b> share your E2EE passcode with anyone you send your accountability reports to.
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="showEnableE2EEForm = true">
                        I understand
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <form id="enableE2eeForm" v-else>
                    <input type="text" name="usr" :value="decryptionPasscodeUsername" style="display: none;" readonly="true" autocomplete="username" />

                    <e2-e-e-passcode-input
                        :autofocus="true"
                        type="password"
                        v-model="e2eePasscode"
                        placeholder="Recovery passcode"
                        :persistent-hint="true"
                        autocomplete="new-password"
                        hint="Passcode">

                    </e2-e-e-passcode-input>

                    <e2-e-e-passcode-input
                        type="password"
                        v-model="e2eePasscodeConfirm"
                        placeholder="Confirm recovery passcode"
                        :persistent-hint="true"
                        autocomplete="new-password"
                        hint="Confirm Passcode">

                    </e2-e-e-passcode-input>

                    <passcode-requirements />

                    <v-btn color="primary" block :disabled="!passCodeValid || dialogLoading" class="mt-2" @click="enableE2EE" :loading="dialogLoading">Enable E2E Encryption</v-btn>
                  </form>
                </v-expand-transition>
              </template>
            </v-card-text>
            <v-card-actions>
              <template v-if="currentState === 0">
                <v-btn outlined color="secondary" @click="closeE2EEDialog" :disabled="dialogLoading">Close</v-btn>
              </template>
              <template v-else>
                <v-btn outlined color="secondary" @click="currentState--" :disabled="dialogLoading">Back</v-btn>
              </template>
              <v-spacer />
              <span v-if="currentState <= 2" class="text-secondary text-body-2">{{currentState + 1}} / 4</span>
              <v-spacer />
              <v-btn v-if="currentState <= 2" @click="currentState++" color="primary">Next</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </template>
    <template v-else>
      <v-col :cols="12" :sm="5" :offset-sm="7" class="pa-2 text-center">
        <v-progress-circular
          :size="25"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </template>
    <v-col :cols="12" :sm="5" :offset-sm="7" class="pa-2" v-if="$store.state.account && $store.state.account.account.debug && e2eeRegistered === true">
      <v-btn @click="unregisterUserFromE2ee" :loading="dialogLoading">Unregister User</v-btn>
    </v-col>
    <v-dialog v-model="showNextSteps" max-width="500">
      <v-card>
        <v-card-title>
          {{nextStepsTitle}}
        </v-card-title>
        <v-card-text v-html="nextStepsHtml">
        </v-card-text>
        <v-card-actions>
          <v-btn block outlined color="primary" @click="closeE2EEDialog">
            {{nextStepsHtmlButtonText || 'Okay'}}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-row>
</template>

<script>
  /* eslint-disable no-console */

  import PasscodeRequirements from "../../e2ee/PasscodeRequirements"
  import AccountApi from "../../../aws/account/AccountApi";
  import StoreConstants from "../../../StoreConstants";
  import E2EEHelperCache from "../../../services/e2ee/E2EEHelperCache";
  import Constants from "../../../Constants";
  import E2EEPasscodeInput from "@/components/e2ee/E2EEPasscodeInput.vue";

  let _e2eeHelper = null

  let forceOpenedE2EEDialog = false

  export default {
    name: "E2EEncryptionManagement",
    components: {
      E2EEPasscodeInput,
      PasscodeRequirements
    },
    data() {
      return {
        showNextSteps: false,
        currentState: 0,
        nextStepsTitle: null,
        nextStepsHtml: null,
        nextStepsHtmlButtonText: null,
        showEnableE2EEForm: false,
        e2eeRegistered: null,
        originalE2eePasscode: '',
        e2eePasscode: '',
        e2eePasscodeConfirm: '',
        showE2EEDialog: false,
        showE2EEPasscodeResetDialog: false,
        showE2EEPasscodeChangeDialog: false,
        dialogLoading: false,
        decryptionPasscodeUsername: 'unknown.decryption.passcode',
        errorMessage: null,
        showHowDoesThisWork: false
      }
    },
    methods: {
      async e2eeFailedToReportBugfix() {
        let e2eeHelper = await this.getE2EEHelper()

        console.log('waiting for e2eeHelper.initPromise')
        await e2eeHelper.initPromise

        if(e2eeHelper.e2eeEnabled) {

          if(!this.$store.getters.isE2EEEnabled) {

            try {
              await AccountApi.recordE2EEAction('enabled') //marks the account as e2ee enabled
              console.log('fixed e2ee failed record')
            } catch(e) {
              console.error('failed to fix e2ee failed record')
              console.log(e)
            }

          }
        }
      },
      async closeE2EEDialog() {
        this.showNextSteps = false
        this.showE2EEDialog = false
        if(forceOpenedE2EEDialog) {
          this.$router.back()
        }
      },
      async setDecryptionPasscodeUsername() {
        await this.$store.state.accountLoadedPromise
        this.decryptionPasscodeUsername = `${this.$store.state.account.accountId}.decryption.passcode`
      },
      async getE2EEHelper() {
        if(_e2eeHelper == null) {
          console.log('waiting for accountLoadedPromise')
          await this.$store.state.accountLoadedPromise
          console.log('waiting for getE2eeHelper')
          // _e2eeHelper = new E2EEHelper(this.$store.state.account.accountId, this.$store.state.account.account.altAccountId)
          _e2eeHelper = E2EEHelperCache.getE2EEHelper(this.$store.state.account.accountId, this.$store.state.account.account.altAccountId)
          await _e2eeHelper.initPromise
          console.log('waiting for getE2eeHelper done')
        }
        return _e2eeHelper
      },
      async unregisterUserFromE2ee() {
        this.dialogLoading = true
        try {
          let e2eeHelper = await this.getE2EEHelper()
          // await e2eeHelper.deletePrivateKey()
          // await e2eeHelper.unregisterUser()
          await e2eeHelper.unregisterAccountAndAllCards()
          location.reload() //hack but we don't anticipate this being used much
        } catch(e) {
          console.log(e)
        } finally {
          this.dialogLoading = false
        }
      },
      async rotatePasscode() {
        if(!this.passCodeValid) {
          return
        }
        this.dialogLoading = true

        try {
          let e2eeHelper = await this.getE2EEHelper()

          let success = false
          for(let c = 0; c < 5 && !success; c++) {
            try {
              await AccountApi.recordE2EEAction('pre-rotate-key')

              // one of these two fails periodically
              await e2eeHelper.rotatePrivateKey()
              await e2eeHelper.backup(this.e2eePasscode)

              await AccountApi.recordE2EEAction('rotate-key')
              success = true //success
            } catch(e) {
              console.error(e)
            }
          }

          await this.$store.dispatch(StoreConstants.actions.loadAccount)

          if(!success) {
            this.$swal('an error occurred, please try again')
            throw new Error('failed')
          }

          this.showE2EEPasscodeResetDialog = false

          this.showNextSteps = true
          this.nextStepsTitle = 'Passcode Reset Successful'
          this.nextStepsHtml = 'Please ensure all of your devices have an active internet connection and then restart them.  This will force the apps to realize the passcode has been reset.  If you do not do this, the apps will encrypt data using the old key and you will not be able to view that data.'
          this.nextStepsHtmlButtonText = 'Okay'
        } catch(e) {
          this.errorMessage = e
          // eslint-disable-next-line no-console
          console.log(JSON.stringify(e))
          this.$swal('An error occured.  Please try again.  If the issue persists, contact support@truple.io.')
        } finally {
          this.dialogLoading = false
        }
      },
      cleanupPasscodeDialog() {
        console.log('cleanup!')
        this.e2eePasscode = ''
        this.e2eePasscodeConfirm = ''
        this.originalE2eePasscode = ''
        this.showEnableE2EEForm = false
      },
      async changePasscode() {
        if(!this.passCodeValid) {
          return
        }
        this.dialogLoading = true

        try {
          let e2eeHelper = await this.getE2EEHelper()

          await AccountApi.recordE2EEAction('pre-update-password')

          await e2eeHelper.changeBackupPassword(this.originalE2eePasscode, this.e2eePasscode)

          await AccountApi.recordE2EEAction('update-password')

          await this.$store.dispatch(StoreConstants.actions.loadAccount)

          this.showE2EEPasscodeChangeDialog = false

          this.showNextSteps = true
          this.nextStepsTitle = 'Passcode Change Successful'
          this.nextStepsHtml = 'Existing devices / browsers that have previously viewed encrypted data will continue to be able to view encrypted data.  Viewing encrypted data from a new device or browser will require entering the new passcode.  Please write this new e2ee passcode down so you don\'t forget it!'
          this.nextStepsHtmlButtonText = 'I wrote it down.'
        } catch(e) {
          // eslint-disable-next-line no-console
          if(e && e.name && e.name === 'WrongKeyknoxPasswordError') {
            this.errorMessage = 'Incorrect password'
            this.$swal('The current password you provided is incorrect.')
          } else {
            this.errorMessage = e
            console.log(JSON.stringify(e))
            this.$swal('an error occured')
          }
        } finally {
          this.dialogLoading = false
        }
      },
      async enableE2EE() {
        if (!this.passCodeValid) {
          return
        }
        this.dialogLoading = true

        try {
          let e2eeHelper = await this.getE2EEHelper()

          console.log('waiting for e2eeHelper.initPromise')
          await e2eeHelper.initPromise

          console.log('a')
          await e2eeHelper.registerUser(this.e2eePasscode)
          console.log('b')
          this.e2eeRegistered = e2eeHelper.e2eeEnabled

          await AccountApi.recordE2EEAction('enabled') //marks the account as e2ee enabled

          this.showE2EEDialog = false

          this.showNextSteps = true
          this.nextStepsTitle = 'End-to-End Encryption Enabled'
          this.nextStepsHtml = `<span>Please do the following:</span><br />
<ol>
  <li><b>Write your e2ee passcode down.</b></li>
  <li>Tell your report recipients your e2ee passcode so they can decrypt your data</li>
  <li>Make sure the Truple apps are updated across all of your devices, then power the device off then back on.</li>
 </ol>
 <br />
 <span>End-to-end encrypted data will have a ${Constants.keyEmoji} symbol near it to indicate it's encrypted end-to-end.</span><br /><br /><span class="h6">Did you write your e2ee passcode down?</v-alert>`
          this.nextStepsHtmlButtonText = 'I wrote the passcode down'
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e)
          this.$swal('an error occured')
        } finally {
          this.dialogLoading = false
        }
      },
      async checkIfE2eeRegisteredWithVirgil() {
        // try {
          let e2eeHelper = await this.getE2EEHelper()
          await e2eeHelper.initPromise
          this.e2eeRegistered = e2eeHelper.e2eeEnabled

          if(!this.e2eeRegistered && this.$route.query.forceEnableE2ee && this.$store.getters.isSubscribed) {
            this.showE2EEDialog = true
            forceOpenedE2EEDialog = true
          }

        // } catch(e) {
        //   console.log('caught')
        //   console.log(e)
        // }
      }
    },
    computed: {
      passCodeValid() {
        return this.e2eePasscode === this.e2eePasscodeConfirm && this.e2eePasscode.length > 6
      }
    },
    beforeMount() {

      if(this.$route.query.e2ee) {
        this.showE2EEDialog = !this.$store.getters.isE2EEEnabled
      }

      this.checkIfE2eeRegisteredWithVirgil()
      this.setDecryptionPasscodeUsername()
    },
    mounted() {
      this.e2eeFailedToReportBugfix()
    }
  }
</script>

<style scoped>

</style>
